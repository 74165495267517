import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";
import Blocked from "./components/Blocked";
import SuperHome from "./pages/SuperHome";
import TfanserHome from "./pages/TfanserHome";
import NormalUserHome from "./pages/NormalUserHome";
const telegram = window.Telegram.WebApp;
function App() {
  const [userInitData, setUserInitData] = useState(null)
  const [userBaseData, setUserBaseData] = useState(null)
  useEffect(() => {
    telegram.ready()
    telegram.expand()
    const userİnfo = async () => {
      try {
        const user = await telegram.initDataUnsafe.user
        console.log(user)
        setUserInitData(user)
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({id:user.id}),
        })
        const result = await response.json()
        if(result.length == 0){
          const addUser = async ()=>{
            try{
              const data = {id:user.id,password:user.id,role:3,is_premium:0,is_verified:0,invited_from:0,username:user.username || "nousername",firstname:user.first_name || "nofirstname",lastname:user.last_name || "nolastname"}
              await fetch(`${process.env.REACT_APP_SERVER_URL}/add-user`,{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
              })
              const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({id:user.id}),
              })
              const result = await response.json()
              setUserBaseData(result[0])
            }catch(error){
              console.log(error)
            }
          }
          addUser()
        }else{
          setUserBaseData(result[0])
        }
      } catch (error) {
        console.log(error)
      }
    }
    userİnfo()
  }, [])
  return (
    <div className="App">
      {(userInitData == null || userInitData == undefined )|| (userBaseData == null || userBaseData == undefined) ? (<Loading />) : (<></>)}
      {userBaseData ? (
        userBaseData.role == 0 ?(
          <Blocked/>
        ):(
          userBaseData.role == 1?(
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<SuperHome/>} />
              </Routes>
            </BrowserRouter>
          ):(
            userBaseData.role == 2?(
              <BrowserRouter>
                <Routes>
                  <Route path='/' element={<TfanserHome/>} />
                </Routes>
              </BrowserRouter>
            ):(
              userBaseData.role == 3?(
                <BrowserRouter>
                  <Routes>
                    <Route path='/' element={<NormalUserHome/>} />
                  </Routes>
                </BrowserRouter>
              ):(
                <></>
              )
            )
          )
        )
      ):(<></>)}
    </div>
  );
}
export default App;
